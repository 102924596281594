import Swiper from 'swiper/bundle';

// トップ
const topSlider = new Swiper('.slide .swiper-container', {
  speed: 1000,
  effect: "slide",
  slidesPerView: 1,
  centeredSlides: true,
  grabCursor: true,
  loop: true,
  autoplay: {
    delay: 2000,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    768: {
      width: 2000,
      slidesPerView: 3.5,
      initialSlide: 2,
      // slidesPerGroup: 3,
      // spaceBetween: 35,
    },
  }

});

// topSlider.autoplay.stop();

// setTimeout(function() {
//   topSlider.autoplay.start();
// }, 2000);
